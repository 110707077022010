import React, { Component } from 'react';
import axios from 'axios';

export default class CreditReportStatus extends Component {

  constructor(props) {
    super(props);

    this.state = {
      reportStatus: 'checking',
      reportUrl: null,
      error: null,
    };
  }

  componentDidMount() {
    this.checkStatus();
  }

  handleCheckStatus = (e) => {
    e.preventDefault();

    this.checkStatus();
  }

  checkStatus = () => {
    const { client_id: clientId, provider: provider } = this.props;

    this.setState({
      reportStatus: 'checking'
    });

    axios.get(`/matrix/lease_clients/${clientId}/credit_reports?provider=${provider}`)
      .then(response => response.data)
      .then((status) => {
        this.setState({
          reportStatus: status
        });
      });
  }

  prepareDownload = (e) => {
    e.preventDefault();

    const { client_id: clientId, provider: provider } = this.props;

    this.setState({
      reportStatus: 'preparing'
    });

    axios.get(`/matrix/lease_clients/${clientId}/credit_reports/render_pdf?provider=${provider}`)
      .then(response => response.data)
      .then((url) => {
        if (url.error) {
          this.setState({
            reportStatus: 'error',
            error: url.error,
          });
        } else {
          this.setState({
            reportStatus: 'ready',
            reportUrl: url,
          });
        }
      });
  }

  render() {
    const { reportStatus, error } = this.state;
    const provider = this.props.provider;

    const providerName = provider.split('_') 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

    switch(reportStatus) {
      case 'checking':
        return (
          <div>
            {providerName} Credit Report Status: Checking... &#x1F575;
          </div>
        )
      case 'created':
        return (
          <div>
            {providerName} Credit Report Status: Queued &#x23F0;
            &nbsp;
            <a href="#" onClick={this.handleCheckStatus}>(Refresh)</a>
          </div>
        )
      case 'requested':
        return (
          <div>
            {providerName} Credit Report Status: Running &#x1F3C3; &#x1F4A8;
            &nbsp;
            <a href="#" onClick={this.handleCheckStatus}>(Refresh)</a>
          </div>
        )
      case 'completed':
        return (
          <div>
            {providerName} Credit Report Status: Available
            <React.Fragment>
              &nbsp;
              <a href="#" onClick={this.prepareDownload}>(Create PDF)</a>
              &nbsp;
              &#x2705;
              &#x2705;
              &#x1F4A5;
            </React.Fragment>
          </div>
        )
      case 'preparing':
        return (
          <div>
            {providerName} Credit Report Status: Preparing PDF &#x1F95A;
          </div>
        )
      case 'ready':
        const { reportUrl } = this.state;

        return (
          <div>
            {providerName} Credit Report Status:
            <React.Fragment>
              &nbsp;
              <a href={reportUrl} target="_blank" >(Download)</a>
              &nbsp;
              &#x1F423;
            </React.Fragment>
          </div>
        )
      case 'frozen':
        return (
          <div>
            {providerName} Credit Report Status: Frozen &#x1F9CA;
          </div>
        )
      case 'duplicate':
        return (
          <div>
            {providerName} Credit Report Status: Duplicate Report &#x1F6A8;&#x1F6A8;&#x1F6A8;
          </div>
        )
      case 'failed':
        return <div>{providerName} Credit Report Status: Failed &#x1F635;</div>;
      case 'error':
        return (
          <div style={{ color: 'red' }}>
            There was an error {providerName} (
{error}
            ), please refresh the page and try again.
          </div>
        )
      default:
        return null;
    }

  }
};
