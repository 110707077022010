import React, { useState, useEffect } from 'react';
import strftime from 'strftime';
import PropTypes from 'prop-types';

import { getParamsFromLink } from 'helpers/util';
import { checkEmail } from 'helpers/util';

import DateSelection from './steps/DateSelection';
import CaptchaConfirmation from './steps/CaptchaConfirmation';
import Success from './steps/Success';
import { handleSetupLead } from './api';
import glassesImg from 'images/illustrations/glasses.png';
import nooklynLogoImg from 'images/nooklyn-logo.svg';
import FullNameInput from './steps/FullNameInput';
import EmailInput from './steps/EmailInput';
import PhoneNumberInput from './steps/PhoneNumberInput';

// Props:
// --------------------------------------------------
// acceptApply: Whether current listing has nooklyn quick apply (Required)
// property: Property object (id and slug)
// type: Type of Property being toured can only be [listings, buildings] (Required)

const ScheduleTour = props => {
  const {
    acceptApply,
    property,
    type,
    closeModal,
    currentUser,
  } = props;
  
  const initialFullName = currentUser && currentUser.name ? currentUser.name : '';
  const initialEmail = currentUser && currentUser.email ? currentUser.email : '';
  const initialPhoneNumber = currentUser && currentUser.phone ? currentUser.phone : '';

  // States for async loading
  const [referringAgent, setReferringAgent] = useState(undefined);

  // States for form information collected
  const [fullName, setFullName] = useState(initialFullName);
  const [fullNameError, setFullNameError] = useState(false);
  const [email, setEmail] = useState(initialEmail);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [scheduleDate, setScheduleDate] = useState(undefined);
  const [userMessage, setUserMessage] = useState('');
  const [showUserMessage, setShowUserMessage] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState(undefined);

  const [successStep, setSuccessStep] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const params = getParamsFromLink();
    if (params['sales-agent'] !== undefined) {
      setReferringAgent(params['sales-agent']);
    }
  }, []);

  // Handles Google Ads Event Firing
  function gtagReportConversion() {
    // eslint-disable-next-line no-undef
    gtag(
      'event', 'malaka', {
        send_to: 'AW-968054781/qt-ECOmgjIACEP2vzc0D',
        value: 1.0,
        currency: 'USD',
        event_callback: () => {},
      },
    );
    return false;
  }

  useEffect(() => {
    setFullNameError(false)
  }, [fullName])

  useEffect(() => {
    setEmailError(false)
  }, [email])

  const checkFullName = () => {
    return fullName.substr(0, fullName.indexOf(' ')).length && 
      fullName.substr(fullName.indexOf(' ') + 1).length
  }

  const validData = () => {
    if (currentUser) {
      return true;
    }
    
    let valid = true;
    if (checkEmail(email)) {
      setEmailError(false)
    } else {
      setEmailError(true)
      valid = false;
    }

    if (checkFullName(fullName)) {
      setFullNameError(false)
    } else {
      setFullNameError(true)
      valid = false;
    }
    
    return valid;
  }

  const utmData = () => {
    const urlParams = new URLSearchParams(new URL(window.location.href).search);
    const utmParams = {};
  
    for (const [key, value] of urlParams) {
      if (key.startsWith('utm_')) {
        utmParams[key] = value;
      }
    }
    return utmParams;
  }

  const submitRequest = () => {
    setSubmitting(true)
    setSubmitError('')
    const dateNote = `${scheduleDate ? `Preferred date: ${strftime('%B %e', scheduleDate)}. ` : ''}`;
    const messageNote = `${userMessage ? `Message: ${userMessage}. ` : ''}`;
    const notes = `${dateNote} ${messageNote}`.trim();
    const firstName = fullName.substr(0, fullName.indexOf(' '));
    const lastName = fullName.substr(fullName.indexOf(' ') + 1);

    handleSetupLead(
      type,
      property.id,
      notes,
      strftime('%F %T %z', scheduleDate),
      firstName,
      lastName,
      phoneNumber,
      email,
      referringAgent,
      utmData(),
    ).then(res => {
      gtagReportConversion();
      setSubmitting(false)
      setSuccessStep(true);
    }).catch(err => {
      console.log(err);
      setSubmitting(false)
      setSubmitError('We were unable to connect you to an agent. Please try again.')
    });
  }

  const handleSubmit = () => {
    if (validData()) {
      submitRequest()
    }
  };

  const handleApply = () => {
    if (type === 'listings') {
      window.location = `/listings/${property.id}/apply?sales-agent=${referringAgent}`;
    }
  };

  const handleRenterGuide = () => {
    window.location = '/guides/renter';
  };

  const handleOnBack = () => {
    window.location = `/${type}/${property.slug}`;
  };

  const handleCaptchaConfirmation = passedCaptchaResponse => {
    setCaptchaResponse(passedCaptchaResponse);
  };

  return (
    <div className="dark-mega-container apply-dark-container">
      <div className="request-tour container">
      <>
        {(!successStep) && (
          <>
            <i className="nookons-close close-btn" onClick={closeModal} />
            <div className='tour-modal-brank-container'>
              <a href="/">
                <img
                  alt="Nooklyn's wordmark"
                  src={nooklynLogoImg}
                  className="modal-brand d-block d-sm-none"
                />
              </a>
            </div>
            <div className='divider d-block d-sm-none'></div>
            <img
              alt="glasses"
              src={glassesImg}
              className="img-fluid mx-auto glasses"
            />
            <h1>Book Tour</h1>
            <FullNameInput 
              initialValue={initialFullName}
              onChange={setFullName}
              error={fullNameError}
              />
            <EmailInput 
              initialValue={initialEmail}
              onChange={setEmail}
              error={emailError}
            />
            <PhoneNumberInput 
              initialValue={initialPhoneNumber}
              onChange={setPhoneNumber}
            />
            <DateSelection
              initialDate={undefined}
              onChange={setScheduleDate}
            />
            { false && (
              <CaptchaConfirmation
                onSubmit={handleCaptchaConfirmation}
              />
              )
            }
            <div className='user-message-container'>
                {
                showUserMessage ? (
                  <div>
                    <label>Message <span className='detail'>(optional)</span></label>
                    <textarea 
                      name='message'
                      className="general-text-field-dark form-control"
                      maxlength="200"
                      onChange={evt => setUserMessage(evt.target.value)}
                    />
                  </div>
                ) : (
                  <div onClick={() => { setShowUserMessage(true) }}>
                    <span className='add-message'>+ Add Message</span><span className='detail'> (optional)</span>
                  </div>
                )
              }
            </div>
            <button
              className={`button btn-yellow-black btn-rounded text-left ${submitting ? 'disabled' : ''}`}
              type="submit"
              onClick={ handleSubmit }
              disabled={submitting}
            >{ submitting ? 'Booking...' : 'Book Tour'}</button>
            {
              submitError ? (
                <div className='submit-error'>
                  We were unable to connect you to an agent. Please try again.
                </div>
              ) : null
            }
            <div className="legal">
              <span>By clicking <b>Book Tour</b>, you agree to our </span> 
              <a href="/terms" target="_blank">Terms of Service</a>
              <span> and </span> 
              <a href="/privacy" target="_blank">Privacy Policy</a>
            </div>
          </>
        )}
        { successStep && (
          <>
            <Success
              onBack={handleOnBack}
            />
            <div className="border-0 text-center">
              <div className="tour-notes pb-3">Not sure what to expect when renting an apartment?</div>
              <div
                className="renter-guide-footer"
                role="button"
                tabIndex={0}
                onKeyDown={evt => {
                  if (evt.key === 'Enter') {
                    handleRenterGuide();
                  }
                }}
                onClick={handleRenterGuide}
              >
                <span className="pr-1 align-middle" style={{ fontWeight: '500' }}>Read the Renter&apos;s Guide</span>
                <i className="nookons-chevron-right align-middle" style={{ fontWeight: '500' }} />
              </div>
            </div>
          </>
        )}
      </>
      </div>
    </div>
  );
};

ScheduleTour.propTypes = {
  acceptApply: PropTypes.bool.isRequired,
  property: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
  }),
  type: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
};

export default ScheduleTour;
